// import Sidebar from '../../components/Sidebar.vue'
// import NavBar from '../../components/NavBar.vue'
import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
    loadingAll: false,
    name: 'PasswordReset',
    mounted() {
    },
    data() {
        return {
            token: this.$route?.query.token,
            email: this.$route?.query.email,
            env: this.$route?.query.env,
            password: '',
            passwordConfirmation: '',
            errorMessage: '',
            loading: false,
            success: false,



            texts: {
                title: 'Recuperação de senha',
                subtitle: 'Por favor, informe a nova senha',

                password: {
                    label: 'Senha',
                    placeholder: 'Digite a senha',
                    errorMsg: 'A senha deve ter pelo menos 8 carácteres'

                },
                confirmPassword: {
                    label: 'Confirmar senha',
                    placeholder: 'Digite a senha novamente',
                    errorMsg: "As senhas devem ser iguais"
                },

                buttonTitle: 'Alterar',
                successTitle: 'Sua senha foi redefinida com sucesso!',
                successSubtitle: 'Você já pode acessar o aplicativo'

            }
        }
    },
    validations: {
        password: { required, minLength: minLength(8) },
        passwordConfirmation: { required, sameAsPassword: sameAs('password') },
    },
    methods: {
        async changePassword() {
            try {
                this.loading = true
                this.errorMessage = ''
                const params = {
                    email: this.email,
                    password: this.password,
                    token: this.token
                }
                const res = await this.$userService.resetPassword(params, this.env)
                this.success = res.data.status || false

                if (!res.data.status) {
                    this.errorMessage = res.data.message
                }
            } catch (e) {
                this.errorMessage = e.message
            }
            finally {
                this.loading = false
            }
        },
    },
    components: {
    }
}