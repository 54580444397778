import EmailConfirmation from '@/views/EmailConfirmation/EmailConfirmation.vue'
import PasswordReset from '@/views/PasswordReset/PasswordReset.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from './paths'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    redirect: {
      name: 'Password Reset'
    }
  },

  {
    path: paths.emailConfirmation,
    name: 'Confirmar Email',
    component: EmailConfirmation,
    props: true,
  },

  {
    path: paths.passwordReset,
    name: 'Password Reset',
    component: PasswordReset,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  paths
})

export default router
