import { minLength, required, sameAs } from 'vuelidate/lib/validators';

export default {
    data() {
        return {
            token: this.$route?.query.token,
            email: this.$route?.query.email,
            env: this.$route?.query.env,
            errorMessage: '',
            loading: false,
            success: false,

            texts: {
                title: 'Validação de email',
                subtitle: 'Por favor, valide seu email',
                buttonTitle: 'Validar email',
                successTitle: 'Seu email foi validado com sucesso!',
                successSubtitle: 'Você já pode acessar o aplicativo'
            }
        }
    },
    methods: {
        async confirmEmail() {
            try {
                this.loading = true
                this.errorMessage = ''
                const params = {
                    email: decodeURI(this.email),
                    password: this.password,
                    token: this.token
                }
                const res = await this.$userService.confirmEmail(params, this.env)
                this.success = res.data.status || false

                if (!res.data.status) {
                    this.errorMessage = res.data.message
                }
            } catch (e) {
                this.errorMessage = e.message
            }
            finally {
                this.loading = false
            }
        },
    },
}