import axios from 'axios'

export default class UserService {
    baseUrl = {
        prod: 'https://apiv2.bluu.us/api/',
        dev: 'https://apiv2-dev.bluu.us/api/',
    }

    endpoints = {
        passwordReset: 'password/reset',
        confirmEmail: 'users/email-verification'
    }

    resetPassword(params, environment = 'prod') {
        const url = `${this.baseUrl[environment]}${this.endpoints.passwordReset}`
        return axios.post(url, params)
    }

    confirmEmail(params, environment = 'prod') {
        const url = `${this.baseUrl[environment]}${this.endpoints.confirmEmail}`
        return axios.post(url, params)
    }
}

